var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SmartAudio", {
    key: _vm.trackId,
    attrs: {
      loop: _vm.loop,
      src: _vm.src,
      paused: _vm.isPaused,
      volume: _vm.volume,
    },
    on: { ended: _vm.onEnded },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }












import Vue from "vue"
import { get } from "lodash"
import { mapGetters } from "vuex"
import type { WithRefs } from "vue-typed-refs"

import type { AudioEntry } from "@/modules/audio/types"
import SmartAudio from "@/modules/smart-media/components/SmartMedia.vue"

import { Event as AudioPlayerEvent } from "@/components/GroupTeams/Misc/AudioPlayer.vue"

export type Refs = {
  player: HTMLAudioElement
}

import { state as smartMediaState } from "@/components/GroupTeams/Common/SmartMedia.vue"

export default (Vue as WithRefs<Refs>).extend({
  name: "LobbyMusic",
  components: { SmartAudio },
  data() {
    return {
      current: null as AudioEntry | null,
      loop: false
    }
  },
  computed: {
    ...mapGetters("soundeffect", ["volume"]),
    ...mapGetters("auth", ["clientID"]),
    isMuted() {
      return Object.keys(smartMediaState.media).length > 0
    },
    src() {
      return this.current?.source
    },
    trackId() {
      return this.current?.id
    },
    isPaused() {
      return this.current?.status === "pause"
    },
    volume() {
      const currentVolume = get(this, "current.volume", 100) / 100
      return this.isMuted ? 0 : currentVolume
    }
  },

  created() {
    this._unsubscribe = {
      current: null,
      loop: null
    }
    this.subscribe()
  },
  beforeDestroy() {
    this.unsubscribe()
  },
  methods: {
    subscribe() {
      return this.$services.get("audio").then(service => {
        this._unsubscribe.current = service.subscribeToCurrent(
          this.clientID,
          current => {
            this.current = current
          }
        )

        this._unsubscribe.loop = service.subscribeToLoop(
          this.clientID,
          value => {
            this.loop = value
          }
        )
      })
    },
    unsubscribe() {
      for (const key in this._unsubscribe) {
        if (this._unsubscribe[key] !== null) {
          this._unsubscribe[key]()
        }
      }
    },

    onEnded() {
      this.$bus.$emit(AudioPlayerEvent.AUDIO_ENDED)
    }
  }
})
